import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../components/button'
import * as Markdown from 'react-markdown'
class CitiesListingTemplate extends Component {
  render() {
    function parseContent(string, altCity) {
      if (string) {
        return string.replace(/{{city}}/g, altCity ? altCity : city)
      }
    }

    const pageData = this.props.data

    const serviceArea = pageData.contentfulServiceArea
    const citiesList = this.props.data.allContentfulServiceArea.edges
    const city = serviceArea.location
    const cityslug = serviceArea.slug
    const tidbits = serviceArea.tidbits.tidbits

    const ListingItemOuterContainer = styled.div`
      background: #fff;
      overflow: hidden;

      margin: 1em 0 !important;
    `

    const ItemTitle = styled.h3`
      margin-bottom: 0.25em;
    `
    const ItemExcerpt = styled.p`
      margin-bottom: 0.65em;
      color: #666;
      font-size: 0.9em;
    `

    const ImageContainer = styled.div``

    const BodyContainer = styled.div`
      padding: 1.25em;
      padding-left: 1.75em !important;
    `

    const ServiceItem = props => {
      let { title, slug, location, image, excerpt } = props
      return (
        <ListingItemOuterContainer
          className={'row shadow-md border-radius overflow-hidden'}
        >
          <ImageContainer className="col-12 col-lg-3">
            <GatsbyImage
              image={getImage(image)}
              alt={city + ' Home Remodeling Contractors'}
              style={{
                marginLeft: '-15px',
                marginRight: '-15px',
                height: '100%',
              }}
            />
          </ImageContainer>

          <BodyContainer className="col-12 col-lg-9">
            <ItemTitle>{title}</ItemTitle>
            <ItemExcerpt>{excerpt}</ItemExcerpt>

            <Button
              to={'/cities/' + cityslug + '/' + slug}
              color={'light'}
              title={'Learn more about ' + title}
            >
              Learn More
            </Button>
          </BodyContainer>
        </ListingItemOuterContainer>
      )
    }

    const Header = styled.div`
      background: #fff;
      padding: 2em 1em;
      h1 {
        font-size: calc(0.75vw + 1.5em);
      }
    `
    const AreasServed = styled.div`
      padding: 2em 1em;
      h1 {
        font-size: calc(0.75vw + 1.5em);
      }
    `

    const Body = styled.div``
    return (
      <Layout pageProps={this.props}>
        <SEO
          title={'Home Remodeling ' + city + ', CA'}
          description={
            'Premier Home Remodeling in ' +
            city +
            ', CA. Get Expert Renovation Advice For Your Custom ' +
            city +
            ' Home Remodel From Solid Construction & Design. Start Your Project Today!'
          }
        />
        <Header>
          <div className="container is-service-page">
          <h1 class="serviceh1custom text-sm">Home Remodeling {city}</h1>
            <h2>
               Transform Your Home with {city}'s Premier Remodeling Company
            </h2>
            <p>Solid Construction & Design is a {city} Home Remodeling Contractor specializing in providing high-quality home remodeling services to {city} homeowners. With decades of experience, our team of skilled professionals can handle any home remodeling project, big or small. From kitchen and bathroom renovations to whole house remodels, {city} Home Remodeling is our expertise.</p>
            {pageData.allContentfulServiceAreaContent.edges.map((item, i) => (
              <ServiceItem
                title={parseContent(item.node.serviceTitle)}
                slug={item.node.slug}
                location={city}
                image={item.node.heroImage}
                excerpt={parseContent(item.node.excerpt)}
              >
                {parseContent(item.node.serviceTitle)}
              </ServiceItem>
            ))}
            <h2 className="mt-8">Home Remodeling Contractor {city}</h2>

            <p>
              Have you been looking around your home and wondering if it's time
              to consider remodeling? There are a variety of reasons people
              consider {city} home remodel. If wear and tear is the issue, then
              remodeling becomes an unavoidable necessity. However, many people
              seek to makeover their home simply because they recognize that
              their living space is an extension of themselves.
            </p>

            <p>
              We will breathe new life into your {city} home with design
              elements that include:
            </p>
            <ul>
              <li>Kitchen Remodeling</li>
              <li>Bathroom Remodeling</li>
              <li>General contractor services</li>
              <li>Granite Countertops</li>
              <li>Quartz Countertops</li>
              <li>Remodeling of your entire home</li>
              <li>Roofing</li>
              <li>Exterior Services</li>
              <li>Home and Room Additions</li>
            </ul>

            <h4>
              Call us now for your free {city} home remodeling estimate at (916)
              260-2066.
            </h4>

            <h2>Free Estimate On Your Next {city} Home Remodeling Project</h2>
            <p>
              When you drive up to a home and it isn’t aesthetically pleasing,
              your eye has a tendency to look away to more attractive looking
              homes. There is no reason why your home should not be one of the
              most appealing homes in your neighborhood. Sometimes a simple
              change of a door can change the whole image of your home. Kitchen
              cabinets, countertops, shower remodel, new hardwood flooring or
              maybe the addition of a porch can give your home a new lease on
              life. Contact Solid Construction &amp; Design today and work with
              our professional contractor's to see what options await you to
              make your home stand out in your neighborhood. Don’t hesitate to
              call us today for your free in-home estimate!
            </p>

            <h3>Reputable, Family-Owned {city} Home Remodeling Company</h3>

            <p>
              Having pride in home and family stands strong and people seem to
              stay in their {city} homes for a fairly long time. With that being
              said, we know {city} remodeling will occur somewhere along the
              line of owning their home. We at Solid Construction & Design look
              forward to your call once you decide you want {city} bathroom
              remodeling services. We know what it takes to make a customer’s
              remodeling dream a reality. We will ask you all the right
              questions to make sure we design what you truly desire.
            </p>

            <h2>Best {city} Home Remodeling Experts</h2>

            <p>
              How would you like the feeling when walking into your {city} home
              kitchen that you were at the heart of your home? When the activity
              in your home picks up momentum, do you have everything in the
              kitchen to take care of your family’s needs? Let us help you
              design a floor plan that will make it convenient for your family--
              whether it’s for quick cup of coffee, a snack, or if you plan on
              entering guests. If you can’t make your family comfortable at
              home, then you need to contact us to see how we can help with
              remodeling your kitchen in {city} today. With our years of
              expertise in design and our home remodeling {city} experience, we
              can help you manage that issue and even have fun while we're
              remodeling. New floors, cabinets, floor plans, lighting,
              appliances, color, fixtures for your home, you name it we work
              with it.
            </p>

            <h3>Creating Beautiful {city} Home Designs</h3>

            <p>
              Do you want your bathtub to make you feel like you can escape and
              relax for a bit? Is your bathroom a bit outdated? Some simple but
              effective changes can make the answers to those questions quite
              appealing. Maybe you just need a change of color, flooring, or
              sink, toilet and tub need an upgrade. Any way you cut it we have
              the contractors to do the job just for you. We will help you
              select everything to make the room functional and the décor
              pleasing. Maybe it’s time for a walk-in tub, a scaled down shower
              or a glorious spa tub for your {city} area Home. Any way you cut
              it, our {city} home remodeling experts at Solid Construction &
              Design will help you incorporate those elements into your {city}{' '}
              home design.
            </p>

            <h3>
              Full-Service Design Build Home Improvement & Home Renovation
            </h3>

            <p>
              You may not have noticed your windows are not sealing as tight as
              they were before or your doors are losing heat or air conditioning
              as they are not as structurally sound as they could be in your
              home. Contact the professionals at Solid Construction & Design. We
              have windows and doors that will enhance your home. Does your home
              need news siding or painting? We have many options for you to
              choose from. In siding alone, we have vinyl, fiber cement siding,
              and wood as a few options. Our specialists are there to take care
              of all your contracting needs. Call Solid Construction & Design
              today to schedule a free in-home estimate. We are there to answer
              all your remodeling questions and provide you with superior
              quality contractors who know how to make your project a success.
            </p>

            <h2>Enhancing Your {city} Home’s Curbside Appeal</h2>

            <p>
              Does your curb side view make you stop and smile when you look at
              your home? If not, we can help with any of your exterior home
              changes as well. Our staff at Solid Construction & Design will
              provide you with a staff of experienced contractors to do change
              your windows, doors, put on new siding, paint your {city} home or
              apply a new roof. We can help you explore different options for
              siding including vinyl, Crane Board or fiber cement siding, and
              we’ll explain the differences between the product so you can
              decide what’s best for you.
            </p>

            <p>
              Whatever you decide we can make your home renovation project dream
              come true. We have years of {city} kitchen and bathroom remodeling
              experience. Plus, we listen to your home renovation needs. Our
              work is done with quality in mind and excellent customer service
              and customer satisfaction is our goal. Contact Solid Construction
              & Design today and let’s build your {city} home remodeling dream!
            </p>
          </div>
        </Header>
        <AreasServed className="bg-color-primary">
          <div className="container is-service-page">
            <div className="pt-4 pb-5">
              <h3 className="text-white">Areas Served</h3>
              <ul className="flex flex-wrap list-none m-0">
                {citiesList.map((item, i) => (
                  <li key={i} className="w-1/2 md:w-1/4 flex">
                    <div class="flex-shrink-0 pr-1">
                      <svg
                        class="h-6 w-6 text-green-500"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>{' '}
                    <Link
                      className="text-white hover:text-gray-200"
                      title={`${'Home Remodeling ' +
                        item.node.location +
                        ', CA'}`}
                      to={`/cities/${item.node.slug}/`}
                    >
                      {item.node.location}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </AreasServed>
        <Body className="container mt-4 mb-4">
          {' '}
          <h4>{city} Home Remodeling Frequently Asked Questions</h4>
          <section
            className="tidbitssec"
            dangerouslySetInnerHTML={{ __html: tidbits }}
          />
        </Body>
      </Layout>
    )
  }
}

export default CitiesListingTemplate

export const pageQuery = graphql`
  query serviceAreaQuery($slug: String!) {
    contentfulServiceArea(slug: { eq: $slug }) {
      id
      slug
      location
      tidbits {
        tidbits
      }
    }
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
        }
      }
    }
    allContentfulServiceAreaContent {
      edges {
        node {
          id
          slug
          serviceTitle
          excerpt
          slug
          heroImage {
            gatsbyImageData(width: 370)
          }
        }
      }
    }
  }
`
